@import "framework";

// Vars

$body-size: 16px;
$ref-width: 1280px;
$body-color: #000000;
$background-color: #ffffff;
$accent-color: #e3fa3f;
$grey: #666666;
$disabled-color: lighten($body-color, 80%);
$body-font-family: 'PlainRegular', sans-serif;
$page-gutter: 2.5em;
$page-gutter-mobile: 1.25em;
$column-gutter: 1em;
$line-thickness: 0.7px;

$header-height: 85px;
$header-height-mobile: 77px;

$mobile: (body-size: 22px, ref-width: 600px, breakpoint: 600px);

// Global

body {
  background-color: $background-color;
  color: $body-color;
  font-family: $body-font-family;
  font-style: normal;
  @include set-body-size;
  @include mobile {
    @include set-body-size;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

button[type=button], .button {
  @include inline-block;
  border: em($line-thickness) solid $grey;
  border-radius: 2.35em;
  background-color: $background-color;
  font-size: 0.75em;
  line-height: 1;
  padding: 0.8em 1.2em;
  cursor: pointer;
  &.on {
    background-color: $accent-color;
  }
  &.disabled {
    color: $disabled-color;
    border: em($line-thickness) solid $disabled-color;
  }
  &.main, &.additional {
    text-transform: uppercase;
  }
  &.artist {
    text-transform: lowercase;
  }
}

input[type=email] {
  border: 0;
  border-bottom: em($line-thickness) solid $grey;
  font-size: 0.8em;
  padding: 0.5em 0;
  outline: 0;
  width: 65%;
  @include placeholder {
    color: $disabled-color;
  }
}

button[type=submit] {
  font-size: 1em;
  padding: 0 0 0 1em;
  border: 0;
  outline: 0;
  background-color: transparent;
  vertical-align: bottom;
  cursor: pointer;
}

.tag-links {
  li {
    @include inline-block;
    margin-right: em(6px);
    margin-bottom: em(6px);
    @include mobile {
      margin-right: em(10px);
      margin-bottom: em(10px);
    }
  }
}

.video-wrap {
  @include fixed-proportions-wrap(16, 9);
}

.image-with-overlay {
  position: relative;
  img {
    height: 100%;
    width: auto;
    @include mobile {
      width: 100%;
      height: auto;
    }
  }
  .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($background-color, 0.8);
    @include flexbox;
    align-items: center;
    justify-content: center;
    @include opacity(0);
    @include transition(0.3s);
    &:hover {
      @include opacity(1);
    }
    .title {
      text-align: center;
      text-transform: uppercase;
      padding: 1em;
    }
  }
}

#loading {
  padding: 0 $page-gutter;
  @include mobile {
    padding: 0 $page-gutter-mobile;
  }
  p { margin: 0; }
}

// App

#app {
  padding-top: em($header-height);
  padding-bottom: $page-gutter;
  @include animation(fadein 1s);
  @include mobile {
    padding-top: em($header-height-mobile);
  }
}

// Header

#app > header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: ($page-gutter * 0.5) $page-gutter;
  text-align: center;
  // background-color: $background-color;
  // box-shadow: 0px 0px 0.3em 0.3em $background-color;
  @include mobile {
    text-align: right;
    padding: ($page-gutter-mobile * 0.5) $page-gutter-mobile;
  }
  .logo {
    display: block;
    width: em(185px);
    position: absolute;
    left: $page-gutter;
    top: ($page-gutter * 0.5);
    @include mobile {
      width: em(264px);
      left: $page-gutter-mobile;
      top: ($page-gutter-mobile * 0.5);
    }
  }
  nav {
    @include inline-block;
    padding-top: 1em;
    @include mobile {
      padding-top: 0.5em;
    }
    ol {
      li {
        @include inline-block;
        text-transform: uppercase;
      }
    }
  }
}

// footer

// #app > footer {
.content > footer {
  .columns {
    padding: 0 ($page-gutter - $column-gutter / 2);
    // @include flexbox;
    // overflow: hidden;
    @include mobile {
      padding: 0 $page-gutter-mobile;
    }
    .column {
      // flex: 1;
      padding: 0 ($column-gutter / 2);
      @include inline-block;
      vertical-align: top;
      @include mobile {
        padding: 0;
        overflow: hidden;
      }
      &:nth-child(1) { width: 40%; }
      &:nth-child(2) { width: 40%; }
      &:nth-child(3) { width: 20%; }
      @include mobile {
        width: 100% !important;
      }
      &:nth-child(1), &:nth-child(2) {
        @include mobile {
          display: none;
        }
      }
      header {
        text-transform: uppercase;
        font-size: 0.8em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        border-bottom: em($line-thickness) solid $grey;
        @include mobile {
          text-align: center;
          cursor: pointer;
        }
      }
      .framed {
        // border-bottom: em($line-thickness) solid $grey;
        padding: 0.6em 0;
        min-height: em(142px);
        @include mobile {
          max-height: 0;
          min-height: 0;
          padding: 0;
          // border-bottom-width: 0;
          @include transition(0.3s ease-in-out);
          &.expanded {
            max-height: 20em;
            padding: 0.6em 0;
            border-bottom-width: em($line-thickness);
          }
        }
        .contact-details {
          font-size: 0.8em;
          line-height: 1.1;
          @include mobile {
            text-align: center;
          }
          .newsletter-form {
            margin-top: 2.25em;
          }
        }
        &.with-cta {
          .text {
            font-size: 1.3em;
            line-height: 1.2;
            @include mobile {
              text-align: center;
            }
          }
          .cta {
            font-size: 0.8em;
            text-align: right;
            margin-left: auto;
            margin-right: 0;
            padding-top: 0.4em;
            @include mobile {
              text-align: center;
            }
          }
        }
        .instagram-feed {
          @include mobile {
            text-align: center;
          }
          li {
            @include inline-block;
            vertical-align: middle;
            margin-right: $column-gutter;
            background-color: black;
            a {
              img {
                width: em(121px);
                height: em(121px);
                object-fit: cover;
                @include opacity(0.95);
                @include mobile {
                  width: em(121px);
                  height: em(121px);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Content

.content {
  @extend .strip-margins-container;
}

// Lightbox

.lightbox {
  position: fixed;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background-color: $background-color;
  .close {
    position: absolute;
    right: em(55px);
    top: em(85px);
    cursor: pointer;
    color: grey;
    width: em(20px);
    height: em(20px);
    @include mobile {
      right: $page-gutter-mobile;
      top: $page-gutter-mobile;
      width: em(25px);
      height: em(25px);
    }
  }
}

// Layers

.content { z-index: 0; }
#app > footer { z-index: 0; }
#app > header { z-index: 10; }
.lightbox { z-index: 20; }

// Homepage

.home-page {
  padding-top: em(90px);
  padding-bottom: em(90px);
  @include mobile {
    padding-top: em(90px);
    padding-bottom: em(90px);
  }
  section + section {
    margin-top: em(90px);
    @include mobile {
      margin-top: em(90px);
    }
  }
  section.featured-projects {
    > ol {
      padding-left: em(52px);
      padding-right: em(52px);
      @include mobile {
        padding-left: $page-gutter-mobile;
        padding-right: $page-gutter-mobile;
      }
      > li {
        @include inline-block;
        width: 50%;
        vertical-align: top;
        padding-left: em(52px);
        padding-right: em(52px);
        @include mobile {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
        a {
          display: block;
          width: 100%;
          h2 {
            font-size: 1.3em;
            line-height: 1.4;
            margin: 0;
          }
        }
        .tag-links {
          margin-top: 0.5em;
        }
      }
      > li + li {
        @include mobile {
          margin-top: em(90px);
        }
      }
    }
  }
  section.about-us {
    width: em(780px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include mobile {
      width: 100%;
      padding-left: $page-gutter-mobile;
      padding-right: $page-gutter-mobile;
    }
    header {
      h2 {
        font-size: 0.8em;
      }
    }
    .text {
      font-size: 1.3em;
      line-height: 1.2;
    }
  }
  section.featured-tag {
    padding: 0 ($page-gutter - $column-gutter / 2);
    @include mobile {
      padding: 0 $page-gutter-mobile;
      display: none;
    }
    > header, > .overview {
      padding: 0 ($column-gutter / 2);
      @include inline-block;
      vertical-align: middle;
      @include mobile {
        padding: 0;
        overflow: hidden;
      }
      &:nth-child(1) { width: 20%; }
      &:nth-child(2) { width: 80%; }
    }
    > header {
      text-align: center;
      h2 { margin-top: 0; }
    }
    > .overview {
      position: relative;
      ol {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        li {
          height: em(225px);
          margin-right: em(6px);
          @include inline-block;
          vertical-align: middle;
          white-space: normal;
          @extend .image-with-overlay;
        }
      }
      &:after {
        content: '';
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
        box-shadow: inset -10em 0 8em -4em $background-color;
        pointer-events: none;
      }
    }
  }
}

// Search page

.search-page {
  padding: em(40px) $page-gutter;
  @include mobile {
    padding: em(80px) $page-gutter-mobile;
  }
  .tags {
    li {
      margin-right: em(6px);
      margin-bottom: em(6px);
      @include mobile {
        margin-right: em(10px);
        margin-bottom: em(10px);
      }
    }
  }
  .search-results {
    padding-top: em(90px);
    @include mobile {
      padding-top: em(90px);
    }
    li {
      height: em(255px);
      margin-right: em(6px);
      margin-bottom: em(6px);
      @include inline-block;
      vertical-align: top;
      @include mobile {
        height: auto;
        width: 100%;
        margin-right: em(10px);
        margin-bottom: em(10px);
      }
    }
    li.artist {
      width: em(380px);
      background-color: $accent-color;
      @include mobile {
        width: 100%;
        height: em(405px);
      }
      > a {
        @include flexbox;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .title {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
    li.project {
      @extend .image-with-overlay;
    }
  }
}

// Lightbox pages (artist and project)
// Things that are used in both

.artist-page, .project-page {
  height: 100%;
  overflow: auto;
  padding-top: em(85px - 4px);
  padding-left: em(205px);
  padding-right: em(205px);
  @include mobile {
    padding-top: ($page-gutter-mobile - 0.2em);
    padding-left: $page-gutter-mobile;
    padding-right: $page-gutter-mobile;
  }
  header {
    h1 {
      font-size: 1.6em;
      line-height: 1;
      margin: 0;
      @include mobile {
        width: 75%;
      }
    }
  }
}

// Artist page

.artist-page {
  background-color: $accent-color;
  display: flex;
  flex-direction: column;
  // padding-right: em(270px);
  header {
    flex: 1;
    .tag-links {
      margin-top: em(75px);
      @include mobile {
        margin-top: em(110px);
      }
    }
    .bio {
      margin-top: em(120px);
      margin-bottom: em(120px);
      @include mobile {
        margin-top: em(105px);
        margin-bottom: em(105px);
      }
      p {
        font-size: 1.3em;
        line-height: 1.2;
      }
    }
  }
  footer {
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    a {
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }
}

// Project page

.project-page {
  // padding-right: em(215px);
  header {
    .tag-links {
      margin-top: em(75px);
      @include mobile {
        margin-top: em(110px);
      }
    }
  }
  .assets {
    padding: em(150px) 0;
    li + li {
      margin-top: em(10px);
    }
    .text {
      p {
        font-size: 1.3em;
        line-height: 1.2;
      }
    }
  }
}

// Transitions

[v-cloak] { display: none; }

@include keyframes(fadein) {
  from {
    @include opacity(0);
  }
  to {
    @include opacity(1);
  }
}

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
}
// .fade-enter-active {
//   transition-delay: 0.3s;
// }
.fade-enter-to, .fade-leave {
  opacity: 1;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

